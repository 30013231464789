<template>
    <div class="popDialog">
        <el-dialog
                v-if="editDialog"
                width="95%"
                top="30px"
                :visible.sync="editDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog">
            <template v-slot:title>
                <el-row>
                    <el-col :span="12" class="selectTitleMax">
                        <span>編輯分店資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>分店名稱<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="branchName" placeholder="請輸入分店名稱"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>分店地址:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="address" placeholder="請輸入分店地址"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isActive">
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="editTemplate">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    export default {
        name: "editBranchDialog",
        components: {
            // pdf,
        },
        props: {
            editDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                extension: "",
                row: null,
                branchName: "",
                address: "",
                token: null,
                companyId: null,
                branchId: null,
                isActive: null,
                reload: false,
            }
        },
        mounted() {
        },
        methods: {
            init(row) {
                this.row = row;
                this.branchId = row.branchId;
                this.companyId = row.companyId;
                this.branchName = row.branchName;
                this.address = row.address;
                this.isActive = row.isActive == true ? "1" : "0";
                console.log("companyName=" + row.branchName);
                console.log("address=" + row.address);
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    //console.log("editBranchDialog取得token=" + this.token);
                }
            },
            closeDialog() {
                console.log("關閉Pop");
                this.value = "";
                this.$emit('update:reload', this.reload);
                this.$emit('closeDialog');
            },
            async editTemplate() {
                //更新
                //開啟特效
                let tmp = this;
                tmp.loadingView();
                let url = "company/updated/branch";
                let parameter = {
                    "branchId": this.branchId,
                    "companyId": this.companyId,
                    "branchName": this.branchName,
                    "address": this.address,
                    "isActive": this.isActive == null ? null : this.isActive == "0" ? false : true,
                };
                try {
                    if (!this.checkAddParameter(parameter)) {
                        console.log("更新驗證資料失敗!");
                        return;
                    }
                    tmp.reload = true;
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                    if (responseData) {
                        tmp.$api.openMsg("更新資料完成!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog();
                            //  tmp.$emit('init');
                        }, 1000);
                    } else {
                        tmp.$api.openMsg("更新資料失敗!", "error");
                        console.log("更新資料失敗!");
                    }
                    // eslint-disable-next-line no-empty
                } catch (error) {
                    console.log("更新資料發生異常! error=" + error);
                } finally {
                    tmp.closeLoading();
                }
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close();
                }
            },
            checkAddParameter(parameter) {
                var ststus = true;
                if (parameter != null && parameter != '') {
                    if (parameter.companyId == null || !parameter.companyId) {
                        console.log("公司ID不可為空!");
                        this.$api.openMsg("公司ID不可為空!", "error");
                        return false;
                    }
                    if (parameter.branchId == null || !parameter.branchId) {
                        console.log("分店ID不可為空!");
                        this.$api.openMsg("分店ID不可為空!", "error");
                        return false;
                    }
                    if (parameter.branchName == null || !parameter.branchName) {
                        console.log("分店名稱不可為空!");
                        this.$api.openMsg("分店名稱不可為空!", "error");
                        return false;
                    }
                    if (parameter.isActive == null || !parameter.isActive) {
                        console.log("分店是否啟用不可為空!");
                        this.$api.openMsg("分店是否啟用不可為空!", "error");
                        return false;
                    }
                } else {
                    console.log("必填資料不可為空!");
                    this.$api.openMsg("必填資料不可為空!", "error");
                    return false;
                }
                return ststus;
            },
        }
    }
</script>

<style scoped>

</style>
