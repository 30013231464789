<template>
    <div class="popDialog">
        <el-dialog
                v-if="addDialog"
                width="95%"
                top="30px"
                :visible.sync="addDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog">
            <template v-slot:title>
                <el-row>
                    <el-col :span="12" class="selectTitleMax">
                        <span>新增分店資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="value" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in options"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>分店名稱<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="branchName" placeholder="請輸入分店名稱"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>分店地址:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="address" placeholder="請輸入分店地址"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isActive">
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="addTemplate">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    export default {
        name: "addBranchDialog",
        components: {
            // pdf,
        },
        props: {
            addDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                branchName: "",
                address: "",
                companyId: null,
                loginReturnDto: null,
                value: null,
                options: [],
                token: "",
                isActive: '1',
                reload: false,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
            }
        },
        mounted() {
        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'));
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'));
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId;
                        this.value = this.loginReturnDto.companyId;
                    } else {
                        this.value = null;
                    }
                    //console.log("addCompanyDialog取得token=" + this.token);
                    console.log("addBranchDialog取得companyId=" + this.companyId);
                }
                this.searchCompanyList();
            },
            closeDialog() {
                console.log("關閉Pop");
                this.$emit('update:reload', this.reload);
                this.$emit('closeDialog');
            },
            async addTemplate() {
                //新增資料
                //開啟特效
                let tmp = this;
                tmp.loadingView();
                let url = "company/addBranch";
                let parameter = {
                    "companyId": this.value,
                    "branchName": this.branchName,
                    "address": this.address,
                    "isActive": this.isActive == null ? null : this.isActive == "0" ? false : true,
                };
                try {
                    if (!this.checkAddParameter(parameter)) {
                        console.log("新增驗證資料失敗!");
                        return;
                    }
                    tmp.reload = true;
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                    if (responseData) {
                        tmp.$api.openMsg("新增資料完成!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog();
                            //   tmp.$emit('init');
                        }, 1000);
                    } else {
                        tmp.$api.openMsg("新增資料失敗!", "error");
                        console.log("新增資料失敗!");
                    }
                } catch (error) {
                    console.log("新增資料發生異常! error=" + error);
                } finally {
                    tmp.closeLoading();
                }
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close();
                }
            },
            async searchCompanyList() {
                //查詢全部公司資料
                let tmp = this;
                let url = "login/search/searchCompanyOptions";
                let parameter = {
                    "companyId": this.companyId,
                    "companyName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url);
                if (responseData != null) {
                    tmp.options = responseData;
                    console.log("取得下拉選單資料 options=" + tmp.options);
                }
            },
            checkAddParameter(parameter) {
                var ststus = true;
                if (parameter != null && parameter != '') {
                    if (parameter.companyId == null || !parameter.companyId) {
                        console.log("公司名稱不可為空!");
                        this.$api.openMsg("公司名稱不可為空!", "error");
                        return false;
                    }
                    if (parameter.branchName == null || !parameter.branchName) {
                        console.log("分店名稱不可為空!");
                        this.$api.openMsg("分店名稱不可為空!", "error");
                        return false;
                    }
                    if (parameter.isActive == null || !parameter.isActive) {
                        console.log("分店是否啟用不可為空!");
                        this.$api.openMsg("分店是否啟用不可為空!", "error");
                        return false;
                    }
                } else {
                    console.log("必填資料不可為空!");
                    this.$api.openMsg("必填資料不可為空!", "error");
                    return false;
                }
                return ststus;
            },
        }
    }
</script>

<style scoped>

</style>
